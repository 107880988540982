import React, { useState, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import './chart.css';

interface EngagementDataProps {
  Days: number;
  Engagement:  number;
}

const Chart3 = () => {
  const [d3Data, setD3Data] = useState<Record<string,EngagementDataProps[]>>({});
  const dummyChart = document.createElement('span');
  const [chart, setChart] = useState<HTMLElement>(dummyChart);
  const HEADERS = [
    'Days', // Day (integer, x-axis)
    'P1',  // Engagement (float, y-axis)
    'P2',
    'P3',
    'P4',
    'P5',
    'P6',
    'P7',
    'P8',
    'P9',
    'P10',
    'P11',
    'P12',
  ];
  const SERIES:Record<string,EngagementDataProps[]> = {
    P1: [],
    P2: [],
    P3: [],
    P4: [],
    P5: [],
    P6: [],
    P7: [],
    P8: [],
    P9: [],
    P10: [],
    P11: [],
    P12: [],
  };

  const margin = {
    top: 20,
    right: 20,
    bottom: 30,
    left: 40,
  };

  useEffect(() => {
    d3.csv('/data/random12_2020825.csv', (d) => {
      let _d:any = null;
      if (d[HEADERS[0]]) { 
        let days = 0;
        for(const header of HEADERS) {
          if (header === HEADERS[0] && d[header]) {
            days = parseInt(d[header], 10);
            continue;
          }
          const engagement = d[header];
          SERIES[header].push({
            Days: days / 365,
            Engagement: (engagement || 0) as number,
          });
        }
        _d = d;
      }
      return _d;
    }).then((data) => {
      if (data.length <= 1) {
        console.error('Error: Data did not load correctly.');
      } else {
        setD3Data(SERIES);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(d3Data).length > 0 && chart !== dummyChart) {
      if (chart.children.length === 0) {
        const height = chart.offsetHeight;
        const width = chart.offsetWidth;
        const svg = d3
          .select('#chart_3')
            .append('svg')
              .attr('height', height)
              .attr('width', width)
            .append('g')
              .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const xScale = d3.scaleLinear()
          .domain([0, 2000 / 365])
          .range([0, width - margin.left - margin.right]);

        const yScale = d3.scaleLinear()
          .domain([0,1])
          .range([height - margin.top - margin.bottom, 0]);

        svg.append('g')
          .attr('class', 'x axis')
          .attr('transform', `translate(0, ${height - margin.bottom - margin.top})`)
          .call(d3.axisBottom(xScale));

        svg.append('g')
          .attr('class', 'y axis')
          .call(d3.axisLeft(yScale).tickFormat(d3.format('.0%')));

        for(const [key, value] of Object.entries(d3Data)) {
          // @ts-expect-error Overload does exist
          svg.append('g')
            .selectAll('.dot')
            .data(value)
            .enter()
            .append('circle')
              .attr('id', key)
              .attr('class', 'dot')
              .attr('r', 1)
              .attr('cx', (d) => xScale(d.Days))
              .attr('cy', (d) => yScale(d.Engagement));
        }
        // TODO: Mouseover tooltips: https://bl.ocks.org/Qizly/5a78caaf03ed96757e72
        // svg.append('rect')
        //   .attr('class', 'overlay')
        //   .attr('width', width - 40)
        //   .attr('height', height - 60);
        
        const plotVerticalLine = (svg: any, x: number, text: string) => {
          const lineTop = margin.top;
          const lineBottom = height - margin.bottom - (margin.top * 1.5);
          const textX = -3;
          const textY = margin.top / 2;
  
          svg.append('line')
            .attr('class', 'vertical-line')
            .attr('x1', xScale(x))
            .attr('y1', lineTop)
            .attr('x2', xScale(x))
            .attr('y2', lineBottom);
          
          svg.append('text')
            .attr('class', 'text-label')
            .text(text)
            // @ts-expect-error X will exist
            .attr('x', xScale(x) + textX)
            .attr('y', textY);
        };

        plotVerticalLine(svg, 0.5, '6 months');
        plotVerticalLine(svg, 1, '1 year');
        plotVerticalLine(svg, 2, '2 years');
        plotVerticalLine(svg, 3, '3 years');
      }
      return;
    }
  }, [d3Data, chart]);

  const chartRef = useCallback((node: HTMLDivElement) => {
    if (chart === dummyChart && node) {
      setChart(node);
    }
  }, []);

  return (
    <div className="chart-outline">
      <p className="caption">Predicted Engagement Rate over Years on Platform</p>
      <div ref={chartRef} id="chart_3" className="chart-container"></div>
    </div>
  );
};

export default Chart3;
