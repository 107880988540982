import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import { SectionProps } from './types';
import InteractiveText from '../InteractiveText';
import Statistic from '../Statistic';
import { Chart1, Chart2 } from '../Chart/';
import illo_4 from '../../img/illo/illo-4.svg';
import {
  Stat89_05,
  Stat80_88,
  Stat86_63,
  Stat23000,
  Stat31,
  Stat3,
  Stat0,
} from '../Statistic/svgs';
import { NavLink, useHistory } from 'react-router-dom';

const Section4: React.FC<SectionProps> = () => {
  const classes = cc({
    middle: true,
  });
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (el.tagName.toLowerCase() !== 'a') {
      history.push('architecture');
    }
    return;
  };
  return (
    <section id="section-4" className={classes}>
      <article className="bg-blue" onClick={handleClick}>
        <Container>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <div className="masthead">
                <div className="caption">AI Architecture</div>
                <img src={illo_4} alt="" />
                <h2 className="monospace">CareSignal AI transforms rare data into accurate, actionable predictions.</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <p className="monospace">An experienced clinician can interpret several disparate data sources to determine a diagnosis. Since we know patient engagement impacts outcomes, an experienced clinician could theoretically study years of data to identify when a patient is about to disengage.</p>
              <p className="monospace">But should they?
                &nbsp;
                  <InteractiveText
                    className="bg-white"
                    image={{
                      src: '../img/cursor/cursor-image-cold-calling.png',
                      alt: '"No one got into medicine for the love of cold calling~" rotates around a female doctor, a hospital, and a male doctor.',
                    }}
                  >
                    No!
                  </InteractiveText> That’s the opposite of top-of-license!</p>
              <p className="monospace">Instead, CareSignal AI can predict patients who will disengage. It’s trained on over 80 features from patient-generated data and metadata. And the machine learning models behind CareSignal AI use best-in class techniques, including:</p>
              <ul className="monospace">
                <li>Ensemble-based Random Survival Forests</li>
                <li>Semi-parametric Cox Regressions</li>
                <li>Survival Analysis Methods</li>
              </ul>
              <p className="monospace">That means CareSignal AI turns this:</p>
              <Chart1 />
              <p className="monospace">into this:</p>
              <Chart2 />
            </Col>
          </Row>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <h2 className="monospace" style={{
                marginTop: '5rem',
                marginBottom: 0,
              }}>CareSignal AI has achieved</h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={6} xl={4}>
              <InteractiveText description={
                <span>
                  <strong>Area Under the Curve</strong><br />
                  LGB: 87.19%<br/>
                  XGB: 86.46%<br/>
                  Stack: 86.23%
                </span>
              }>
                <Statistic number={<Stat86_63 />} description="Average AUC" />
              </InteractiveText>
            </Col>
            <Col lg={6} xl={4}>
              <InteractiveText description={
                  <span>
                    <strong>Accuracy</strong><br />
                    LGB: 89.25%<br/>
                    XGB: 89.32%<br/>
                    Stack: 89.05%
                  </span>
                }>
                  <Statistic number={<Stat89_05 />} description="Average Accuracy" />
                </InteractiveText>
            </Col>
            <Col lg={12} xl={4}>
              <InteractiveText description={
                <span>
                  <strong>Recall</strong><br/>
                  LGB: 81.93%<br/>
                  XGB: 81.70%<br/>
                  Stack: 79.01%
                </span>
              }>
                <Statistic number={<Stat80_88 />} description="Average Recall" />
              </InteractiveText>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <div className="monospace">CareSignal AI has achieved all of this with just a few kilobytes of data and metadata for any given patient.&nbsp;
                <InteractiveText description={
                  <ol>
                    <li>data: CS collects data with good quality</li>
                    <li>feature engineering: targeted feature engineering methods have been applied based on CS data</li>
                    <li>algorithm(s): effective algorithms are chosen for building models</li>
                    <li>hyperparameter tuning: effective optimization method for fine-tuning the model;</li>
                    <li>metrics: effective metrics have been used for measuring model performance.</li>
                  </ol>
                }>How can CareSignal AI make such precise predictions?</InteractiveText>&nbsp;
                Well, although our datasets are orders of magnitude smaller than self-driving cars, our predictive engine is driven by the same machine learning theory:
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={4}><Statistic number={<Stat23000 />} description="Patients"/></Col>
            <Col lg={6} xl={4}><Statistic number={<Stat31 />} description="Features"/></Col>
            <Col lg={6} xl={4}><Statistic number={<Stat3 />} description="Models"/></Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <img src="./img/illo/illo_section-four-model.png" alt="Points connected by dotted lines represent new insights from patient data. These points are transforming into an irregular 3D shape that looks liked it's extruded from the points. The resulting 3D object represents a real, tangible model that can be applied to the real-world." />
              <p className="monospace">Using hyperparameter-based tuning, CareSignal AI squeezes even more value–and better accuracy–from every byte. The insights we generate are actionable, not academic. More on that soon. But first: all of CareSignal AI operates without any purchased, licensed, or consumer data. </p>
              <InteractiveText
                image={{
                  src: './img/cursor/cursor_hover-lexis.png',
                  alt: '"LexisNexis? Lexis no thanks!" rotating around a red book.'
                }}
              >
                <Statistic number={<Stat0 />} description="Third-party data" />
              </InteractiveText>
              <p className="monospace">That means that as soon as a&nbsp;
                <InteractiveText
                  className="bg-white"
                  description={
                    <span>
                      Like these awesome organizations!<br/>
                      <img src="./img/logo/logo-Mercy.png" alt="Mercy" />
                      <img src="./img/logo/logo-Americares.png" alt="Americares" />
                      <img src="./img/logo/logo-InnovationHealth.png" alt="Innovation Health" />
                      <img src="./img/logo/logo-UnityPointHealth.png" alt="UnityPoint Health" />
                    </span>
                  }
                >
                  new partner
                </InteractiveText>
                &nbsp;begins working with CareSignal, that organization can reap the benefits of CareSignal’s predictive and preventative patient engagement immediately.</p>
            </Col>
          </Row>
          <p className="text-center"><NavLink className="navigation-button" to="re-engagement">Next →</NavLink></p>
        </Container>
      </article>
    </section>
  );
};

export default Section4;
