import React, { useEffect } from 'react';
import { setConfiguration } from 'react-grid-system';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Section1, Section2, Section3, Section4, Section5, Section6, Section7 } from './components/Section';
// import Test3D from './components/Tests/Test3D';
// import Test3DTransitions from './components/Tests/Test3DTransitions';

// Components
import NavigationItem from './components/Navigation';
import Cursor from './components/Cursor';

// Styles and graphics
import './App.css';

/**
 * TODOS:
 * 
 * General
 * - [x] <CursorImage /> ---> <CursorBlock /> to accomodate text blocks
 * - [x] Remove animation from <InteractiveText />
 * - [x] fix fonts
 * - [ ] encourage people to use modern browser
 * 
 * Mobile
 * - [x] typography on mobile
 * - [x] Hide cursor on mobile
 * - [ ] Use onHover springs
 * 
 * Navigation Context
 * - [ ] Section parent
 * - [ ] Navigation parent
 * - [ ] Gracefully slide between sections
 * - [x] better looking page wayfinding and navigation
 * - [ ] Unmount / mount sections
 * 
 * D3
 * - [x] Create <Chart />
 * - [x] Random scatterplot with one patient's data
 * - [x] Survival plot with one patient's data
 * - [x] Survival plot with 12 patients' data
 * 
 * 3D Rendering
 * - [ ] break into /types and /utils
 * - [ ] Update <Path /> to update length between two points based on scroll progress
 * 
 * Optimization
 * - [ ] THREE scissor function
 * - [ ] optimize rendering / react rendering
 * - [ ] graceful degredation for IE11 (no three.js)
 * - [ ] Fix WebGL warnings
 * - [ ] Revisit adherence to outlined SceneGraph
 */

const SECTIONS = {
  'intro': {
    path: 'intro',
    title: 'Intro',
  },
  'rare-data': {
    path: 'rare-data',
    title: 'Rare Data',
  },
  'data-collection': {
    path: 'data-collection',
    title: 'Data Collection',
  },
  'architecture': {
    path: 'architecture',
    title: 'Architecture',
  },
  're-engagement': {
    path: 'engagement',
    title: 'Re-Engagement',
  },
  'operationalization': {
    path: 'operationalization',
    title: 'Operationalization',
  },
  'pricing': {
    path: 'pricing',
    title: 'At-Risk Pricing',
  },
};
const NAV_KEYS = [
  'ArrowRight', 
  'ArrowLeft', 
  'ArrowUp', 
  'ArrowDown',
];

function App() {

  // https://css-tricks.com/snippets/css/animated-grainy-texture/

  // sm, md, lg, xl, xxl
  setConfiguration({
    gridColumns: 12,
    breakpoints: [576, 768, 992, 1340, 1600],
    containerWidths: [540, 740, 960, 1306, 1540],
  });

  /**
   * [x] arrow keys
   * [x] sharable /path-to-section
   * [x] clicking on edges
   * [x] next buttons
   * [ ] scroll to next
   **/
  return (
    <div className="App">
      <Switch>
        <Route path="/:section" render={({ match, history }) => <Index match={match} history={history} /> } />
        <Route path="*" render={() => <Redirect to="/intro" />}/>
      </Switch>
    </div>
  );
}

interface IndexProps {
  match: any;
  history: any;
}

const Index :React.FC<IndexProps> = ({ match, history }) => {
  const params = match.params as any;
  const section = params.section;
  const index = Object.keys(SECTIONS).indexOf(section);

  const handleKeyUp = (event: KeyboardEvent) => {
    const i = Object.keys(SECTIONS).indexOf(section);
    const key = event.key;
    if (NAV_KEYS.indexOf(key) > -1) {
      const _index = (key === 'ArrowRight' || key === 'ArrowUp')
        ? Math.min(i + 1, Object.keys(SECTIONS).length - 1)
        : Math.max(i - 1, 0);
      // Allow history to be modified no matter what
      history.push(Object.keys(SECTIONS)[_index]);
      if (i <= 0 || i >= Object.keys(SECTIONS).length - 1) {
        // Reached bounds of navigation, add event listener again
        document.addEventListener('keyup', handleKeyUp, { once: true });
      }
    } else {
      // Wrong key pressed, add event listener again
      document.addEventListener('keyup', handleKeyUp, { once: true });
    }
    return;
  };
  
  useEffect(() => {
    const params = match.params;
    const section = params.section;
    // @ts-expect-error ignore section type any
    const _section = SECTIONS[section] || SECTIONS['intro'];
    document.title = `CareSignal AI • ${_section.title}` || 'CareSignal AI';
    document.addEventListener('keyup', handleKeyUp, { once: true });
    return () => {
      // Clean up event listener
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [section]);

  return (
    (index > -1) 
      ?
        <div>
          <Cursor>
            {/* <Test3DTransitions /> */}
            {/* <Test3D /> */}
            <Section1 isTurned={index > 0} /> 
            <Section2 isTurned={index > 1} />
            <Section3 isTurned={index > 2} />
            <Section4 />
            <Section5 isTurned={index > 3} />
            <Section6 isTurned={index > 4} />
            <Section7 isTurned={index > 5} />
            <nav>
              <NavigationItem next="/intro" index={0} text="Intro" defaultVisited={index > 0} defaultActive={0 === index} />
              <NavigationItem next="/rare-data" index={1} text="Rare Data" defaultVisited={index > 1}  defaultActive={1 === index} />
              <NavigationItem next="/data-collection" index={2} text="Data Collection" defaultVisited={index > 2} defaultActive={2 === index} />
              <NavigationItem next="/architecture" index={3} text="AI Architecture" defaultVisited={index > 3} defaultActive={3 === index} />
              <NavigationItem next="/re-engagement" index={4} text="Re-Engagement" defaultVisited={index > 4} defaultActive={4 === index} />
              <NavigationItem next="/operationalization" index={5} text="Impact" defaultVisited={index > 5} defaultActive={5 === index} />
              <NavigationItem next="/pricing" index={6} text="At-Risk Pricing" defaultActive={6 === index} />
            </nav>
          </Cursor>
        </div>
      : <Redirect to="/intro" />
  );
};

export default App;
