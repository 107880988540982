import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import { SectionProps } from './types';
import { Chart3 } from '../Chart/';
import { NavLink, useHistory } from 'react-router-dom';

const Section6: React.FC<SectionProps> = ({ isTurned }) => {
  const classes = cc({
    right: true,
    turned: isTurned,
  }); 
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (isTurned && el.tagName.toLowerCase() !== 'a') {
      history.push('operationalization');
    }
    return;
  };
  return (
    <section id="section-6" className={classes}>
      <article className="bg-green" onClick={handleClick}>
        <Container>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <div className="masthead">
                <div className="caption">Impact</div>
                <img src="./img/illo/illo_section-six-masthead.png" alt="Stacks of bills with a portrait of a robot instead of a historical figure." />
                <h2 className="serif">Artificial Intelligence.<br />Real impact.</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <p className="monospace">Too often, AI is an academic exercise that never sees the real world. CareSignal AI, on the other hand, helps us create more clinical and financial value to our patients and partners every day.</p>
              <Chart3 />
              <p className="monospace">By determining the optimal engagement sensitivity, even on a partner-by-partner basis, CareSignal AI brings our clients – industry-leading health systems and payers – predictive re-engagement, all with the white-labeled, high-quality, real human interactions that earn CareSignal the trust of many of the biggest names in healthcare. </p>
              <p className="monospace">And we do it all with greater impact and a fraction of the resources that a brute-force approach would require.</p>
            </Col>
          </Row>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <h2 className="serif">Proven clinical outcomes<br />from sub-clinical AI.</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <p className="monospace">While there’s great promise for AI-powered clinical decision support, there are also real risks for AI that “play doctor.” That’s why any clinical AI, per the FDA, is regulated as a medical device–Software as a Medical Device. CareSignal AI, however, remains strictly sub-clinical.</p>
              <img src="./img/illo/illo_section-six-10x.png" alt="A 3D barchart showing that CareSignal AI-informed outreach is 10 times better than manual. With manual outreach, only get 1 successfully re-engaged patient per hour. With AI-informed outreach, get 11.4 successfully re-engaged patients per hour." />
              <p className="monospace">CareSignal AI works silently in the background, focused only on predicting which patients will disengage. It stays out of the way so that care managers and physicians can receive the actionable, patient-generated data they need to make decisions and do what’s best for each patient.</p>
              <p className="monospace">Longitudinally, the impacts are substantial: CareSignal AI brings superior engagement, and thus greater ROI, to our partners and their patients. We are proud to deliver substantive AI-driven solutions, and a real step toward preventative, value-based healthcare.</p>
            </Col>
          </Row>
          <p className="text-center"><NavLink className="navigation-button" to="pricing">Next →</NavLink></p>
        </Container>
      </article>
    </section>
  );
};

export default Section6;
