import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import { SectionProps } from './types';
import InteractiveText from '../InteractiveText';
import { NavLink, useHistory } from 'react-router-dom';

const Section5: React.FC<SectionProps> =  ({ isTurned }) => {
  const classes = cc({
    right: true,
    turned: isTurned,
  });
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (isTurned && el.tagName.toLowerCase() !== 'a') {
      history.push('re-engagement');
    }
    return;
  };
  return (
    <section id="section-5" className={classes}>
      <article className="bg-blue-green" onClick={handleClick}>
        <Container>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <div className="masthead">
                <div className="caption">Re-Engagement</div>
                <img src="./img/illo/illo_section-five-masthead.png" alt="A sphere, representing an insight from CareSignal.ai emerging from an irregular-shaped box, representing a unique model." />
                <h2 className="serif">CareSignal AI does more than predict disengagement–it&nbsp;delivers effective, preventative re-engagement.</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <p className="monospace">The Deviceless Remote Patient Monitoring solution CareSignal delivers is accessible, scalable, and deeply&nbsp;
                <InteractiveText
                  className="bg-black"
                  image={{
                    src: './img/cursor/cursor-image-peer-reviewed-publications.png',
                    alt: '"Read our 11+ peer-reviewed publications!" rotates around a stack of three books.',
                  }}
                >
                  evidence-based
                </InteractiveText>. But as with all technology, it only works as long as patients are&nbsp;
              <InteractiveText
                description={
                  <span>
                    <strong>engaged (n)</strong>: Providing clinically relevant and actionable data<br/>
                    Example: “CareSignal really keeps patients engaged! The average clinically relevant engagement duration is over a year!”
                  </span>
                }
              >engaged</InteractiveText>
              . That’s where CareSignal AI comes in: making sure patients stay engaged for as long as possible by flagging the ones who would fall off, unless someone intervenes.</p>
              <img src="./img/illo/illo_section-five-highlight.png" alt="An irregular 3D shape, representing a unique model, is pointing out three patients at-risk of disengaging." />
              <h2 className="serif">And. It. Works.</h2>
              <p className="monospace">And it’s working right now. For the small percentage of the global active patient population that would disengage in any given week, CareSignal AI not only predicts who those patients are...</p>
              <img src="./img/illo/illo_section-five-1.5.png" alt="A 3D pie chart has a red sliver floating above it, representing the 1.5% of patients that will disengage on any given week. Three patients from earlier are shown again because they are part of this 1.5%." />
              <p className="monospace">…it informs preventative outreach…</p>
              <img src="./img/illo/illo_section-five-outreach.png" alt="A care manager is now calling out to those disengaged patients, turning them re-engaged." />
              <p className="monospace">...and prevents 47%-57% of patients from disengaging.</p>
              <img src="./img/illo/illo_section-five-57.png" alt="The same 3D pie chart from earlier shows the red sliver as 57% green. Showing that with CareSignal AI-powered outreach, 57% of patients remain engaged." />
              <p className="monospace">In fact, even better than preventing 57% of patients from disengaging, CareSignal AI improves the engagement quality for about two thirds of the patients who would have otherwise fallen off.</p>
            </Col>
          </Row>
        </Container>
        <p className="text-center"><NavLink className="navigation-button" to="operationalization">Next →</NavLink></p>
      </article>
    </section>
  );
};

export default Section5;
