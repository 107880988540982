import React, { useState, useCallback } from 'react';
import CursorBlock from '../Cursor/CursorBlock';

interface TextInteractiveProps {
  children: any;
  className?: string;
  description?: string | JSX.Element;
  image?: {
    src: string;
    alt: string;
  };
}

const InteractiveText: React.FC<TextInteractiveProps> = ({ children, className, description, image }) => {
  const dummyText = document.createElement('div');
  const [textNode, setTextNode] = useState<HTMLElement>(dummyText);
  const textRef = useCallback((node: HTMLElement) => {
    if (textNode === dummyText && node) {
      setTextNode(node);
    }
  }, []);
  return (
    (image || description)
      ? <CursorBlock className={className} image={image} description={description}>
          <span ref={textRef}>
            {children}
          </span>
        </CursorBlock>
    : null
  );
};

export default InteractiveText;

