import React from 'react';

const Stat0: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.74 56.16">
      <g id="outlined-text-0">
        <path className="statistic" d="M150,10c13.54,0,19.37,9.15,19.37,27.65,0,18.15-5.83,27.51-19.37,27.51-13.1,0-19.37-9.22-19.37-27.51C130.63,19.18,137,10,150,10Zm7,27.65c0-13-1.66-18.64-7-18.64s-7,5.61-7,18.64c0,13.69,1.58,18.51,7,18.51C155.55,56.19,157,50.79,157,37.68Z" transform="translate(-130.13 -9.53)"/>
      </g>
    </svg>
  );
};

const Stat3: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.48 56.23">
      <g id="outlined-text-3">
        <path className="stat" d="M144.67,41.36V32.64h4.61c4.25,0,7.78-2.37,7.78-6.84,0-4.1-2.52-6.91-6.91-6.91-5,0-7.49,2.95-7.78,7.63h-11c.22-8.13,5.41-16.63,19.09-16.63,11.3,0,17.35,6.41,17.35,13.83,0,6.26-3.17,10.08-8.57,12.38v.15c5.76,1.65,9.94,5.54,9.94,13,0,8.56-6.63,15.91-19.16,15.91-13.82,0-18.79-8.14-19.29-16.56H142c.5,5,3.89,7.49,8.14,7.49,4.6,0,8.13-2.88,8.13-7.49s-2.95-7.2-8.06-7.2Z" transform="translate(-130.25 -9.39)"/>
      </g>
    </svg>
  );
};

const Stat89_05: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.06 58.03">
      <g id="outlined-text-89.05_" data-name="outlined-text-89.05%">
        <path className="stat" d="M34.8,9.89c11.88,0,18.51,6.62,18.51,14.76A12.13,12.13,0,0,1,45,36.6v.22c5.54,1.44,10.08,5.33,10.08,12.6,0,9.22-7.64,15.91-20.31,15.91S14.5,58.71,14.5,49.49c0-7.27,4.6-11.23,10.08-12.67V36.6c-4.68-1.51-8.28-5.83-8.28-12C16.3,16.51,22.92,9.89,34.8,9.89Zm8.64,39.17c0-5.47-3.74-7.92-8.64-7.92s-8.57,2.45-8.57,7.92,3.82,8,8.57,8S43.44,54.46,43.44,49.06Zm-16-23.33c0,5,3.31,7.56,7.41,7.56s7.35-2.52,7.35-7.56-3.24-7.49-7.35-7.49S27.39,20.55,27.39,25.73Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M72.89,50.65c.65,4,3.31,5.76,7,5.76,5.55,0,9.08-5.19,9-16.85h-.44A12.35,12.35,0,0,1,78,44.67c-9,0-16.78-5.47-16.78-16.78,0-9.86,6.84-18,18.8-18,12.38,0,20.59,7.56,20.59,25.56,0,21.17-8,29.67-20.74,29.67-10.51,0-17-5-17.93-14.47Zm-.36-23c0,6,3.53,9,7.63,9,4.68,0,8.07-2.88,8.07-9s-3.82-8.93-7.85-8.93S72.53,21.48,72.53,27.68Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M120.48,51.08h13V64h-13Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M173,10c13.54,0,19.37,9.15,19.37,27.65,0,18.15-5.83,27.51-19.37,27.51-13.1,0-19.37-9.22-19.37-27.51C153.67,19.18,160.08,10,173,10Zm7,27.65c0-13-1.66-18.64-7-18.64s-7,5.61-7,18.64c0,13.69,1.58,18.51,7,18.51C178.59,56.19,180,50.79,180,37.68Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M211,48.56c.5,5.25,3.67,7.63,7.77,7.63,4.9,0,8.43-2.81,8.43-9.65,0-6.26-3-9.29-7.92-9.29a8.39,8.39,0,0,0-7.49,3.82H201.41l1.15-30.39H235.4v9.44H212l-.5,12.6h.5c2.52-2.67,5.84-4,10.73-4,9.72,0,16,6.34,16,17.36,0,10.51-6.55,19.08-19.59,19.08-14.18,0-18.94-8.28-19.37-16.56Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M254,8.45c6.12,0,11.38,4.18,11.38,12.67S260.16,33.94,254,33.94s-11.38-4.32-11.38-12.82S247.92,8.45,254,8.45Zm27.44-.14h6.62L249.29,64h-6.55ZM250.22,21.12c0,4.54,1.3,6.77,3.75,6.77,2.23,0,3.82-2.23,3.82-6.77s-1.59-6.84-3.82-6.84C251.52,14.28,250.22,16.51,250.22,21.12ZM265.06,51.8c0-8.57,5.25-12.75,11.38-12.75s11.37,4.18,11.37,12.75-5.25,12.74-11.37,12.74S265.06,60.22,265.06,51.8Zm15.12,0c0-4.54-1.51-6.84-3.82-6.84s-3.74,2.16-3.74,6.84c0,4.46,1.37,6.77,3.74,6.77S280.18,56.26,280.18,51.8Z" transform="translate(-14 -7.81)"/>
      </g>
    </svg>
  );
};

const Stat80_88: React.FC = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.06 58.03">
      <path className="stat" d="M20.81,2.08c11.88,0,18.5,6.63,18.5,14.76A12.15,12.15,0,0,1,31,28.8V29c5.55,1.44,10.08,5.33,10.08,12.61,0,9.21-7.63,15.91-20.3,15.91S.5,50.9.5,41.69C.5,34.41,5.11,30.45,10.58,29V28.8C5.9,27.29,2.3,23,2.3,16.84,2.3,8.71,8.92,2.08,20.81,2.08Zm8.64,39.17c0-5.47-3.75-7.92-8.64-7.92s-8.57,2.45-8.57,7.92,3.81,8,8.57,8S29.45,46.66,29.45,41.25ZM13.39,17.92c0,5,3.31,7.57,7.42,7.57s7.34-2.52,7.34-7.57-3.24-7.48-7.34-7.48S13.39,12.74,13.39,17.92Z"/>
      <path className="stat" d="M66.89,2.23c13.53,0,19.37,9.14,19.37,27.65,0,18.14-5.84,27.5-19.37,27.5-13.11,0-19.37-9.21-19.37-27.5C47.52,11.37,53.92,2.23,66.89,2.23Zm7,27.65c0-13-1.66-18.65-7-18.65s-7,5.61-7,18.65c0,13.68,1.59,18.5,7,18.5S73.87,43,73.87,29.88Z"/>
      <path className="stat" d="M106.49,43.27h13v13h-13Z"/>
      <path className="stat" d="M159.05,2.08c11.88,0,18.5,6.63,18.5,14.76a12.15,12.15,0,0,1-8.28,12V29c5.55,1.44,10.08,5.33,10.08,12.61,0,9.21-7.63,15.91-20.3,15.91s-20.31-6.63-20.31-15.84c0-7.28,4.61-11.24,10.08-12.68V28.8c-4.68-1.51-8.28-5.83-8.28-12C140.54,8.71,147.17,2.08,159.05,2.08Zm8.64,39.17c0-5.47-3.75-7.92-8.64-7.92s-8.57,2.45-8.57,7.92,3.81,8,8.57,8S167.69,46.66,167.69,41.25ZM151.63,17.92c0,5,3.31,7.57,7.42,7.57s7.34-2.52,7.34-7.57-3.24-7.48-7.34-7.48S151.63,12.74,151.63,17.92Z"/>
      <path className="stat" d="M205.13,2.08c11.88,0,18.5,6.63,18.5,14.76a12.15,12.15,0,0,1-8.28,12V29c5.55,1.44,10.08,5.33,10.08,12.61,0,9.21-7.63,15.91-20.3,15.91s-20.31-6.63-20.31-15.84c0-7.28,4.61-11.24,10.08-12.68V28.8c-4.68-1.51-8.28-5.83-8.28-12C186.62,8.71,193.25,2.08,205.13,2.08Zm8.64,39.17c0-5.47-3.75-7.92-8.64-7.92s-8.57,2.45-8.57,7.92,3.81,8,8.57,8S213.77,46.66,213.77,41.25ZM197.71,17.92c0,5,3.31,7.57,7.42,7.57s7.34-2.52,7.34-7.57-3.24-7.48-7.34-7.48S197.71,12.74,197.71,17.92Z"/>
      <path className="stat" d="M240,.64c6.13,0,11.38,4.18,11.38,12.68S246.17,26.13,240,26.13s-11.37-4.32-11.37-12.81S233.92.64,240,.64ZM267.48.5h6.62L235.29,56.23h-6.55ZM236.23,13.32c0,4.53,1.29,6.76,3.74,6.76,2.24,0,3.82-2.23,3.82-6.76S242.21,6.48,240,6.48C237.52,6.48,236.23,8.71,236.23,13.32ZM251.06,44c0-8.57,5.26-12.74,11.38-12.74S273.82,35.42,273.82,44s-5.26,12.75-11.38,12.75S251.06,52.42,251.06,44Zm15.12,0c0-4.53-1.51-6.84-3.81-6.84s-3.75,2.16-3.75,6.84c0,4.47,1.37,6.77,3.75,6.77S266.18,48.46,266.18,44Z"/>
    </svg>
  );
};

const Stat86_63: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.06 58.03">
      <g id="outlined-text-86.63_" data-name="outlined-text-86.63%">
        <path className="stat" d="M34.8,9.89c11.88,0,18.51,6.62,18.51,14.76A12.13,12.13,0,0,1,45,36.6v.22c5.54,1.44,10.08,5.33,10.08,12.6,0,9.22-7.64,15.91-20.31,15.91S14.5,58.71,14.5,49.49c0-7.27,4.6-11.23,10.08-12.67V36.6c-4.68-1.51-8.28-5.83-8.28-12C16.3,16.51,22.92,9.89,34.8,9.89Zm8.64,39.17c0-5.47-3.74-7.92-8.64-7.92s-8.57,2.45-8.57,7.92,3.82,8,8.57,8S43.44,54.46,43.44,49.06ZM27.38,25.73c0,5,3.32,7.56,7.42,7.56s7.35-2.52,7.35-7.56-3.24-7.49-7.35-7.49S27.38,20.55,27.38,25.73Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M89.09,24.22c-.5-3.39-2.74-5.47-6.19-5.47-6.2,0-8.86,6.33-9.29,15.41h.58c2.16-2.88,5.83-4.54,10.94-4.54,7.27,0,15.7,4.68,15.7,17.07,0,9.5-6.27,18.43-19.59,18.43C69.29,65.12,61.8,57.41,61.8,40c0-21.24,8.14-30.1,20.67-30.1,11.3,0,16.77,6,17.35,14.33Zm.36,22.54c0-5.84-2.66-9.36-7.49-9.36s-7.63,3.45-7.63,9.36,2.74,9.43,7.56,9.43S89.45,52.66,89.45,46.76Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M120.48,51.08h13V64h-13Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M181.25,24.22c-.5-3.39-2.74-5.47-6.19-5.47-6.19,0-8.86,6.33-9.29,15.41h.58c2.16-2.88,5.83-4.54,10.94-4.54,7.27,0,15.7,4.68,15.7,17.07,0,9.5-6.27,18.43-19.59,18.43-12,0-19.44-7.71-19.44-25.13,0-21.24,8.14-30.1,20.67-30.1,11.3,0,16.77,6,17.35,14.33Zm.36,22.54c0-5.84-2.66-9.36-7.49-9.36s-7.63,3.45-7.63,9.36,2.74,9.43,7.56,9.43S181.61,52.66,181.61,46.76Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M213.79,41.36V32.64h4.61c4.25,0,7.78-2.37,7.78-6.84,0-4.1-2.52-6.91-6.91-6.91-5,0-7.49,2.95-7.78,7.63h-11c.22-8.13,5.41-16.63,19.09-16.63,11.3,0,17.35,6.41,17.35,13.83,0,6.26-3.17,10.08-8.57,12.38v.15c5.76,1.65,9.94,5.54,9.94,13,0,8.56-6.63,15.91-19.16,15.91-13.82,0-18.79-8.14-19.29-16.56h11.23c.5,5,3.89,7.49,8.14,7.49,4.6,0,8.13-2.88,8.13-7.49s-3-7.2-8.06-7.2Z" transform="translate(-14 -7.81)"/>
        <path className="stat" d="M254,8.45c6.12,0,11.38,4.18,11.38,12.67S260.16,33.94,254,33.94s-11.38-4.32-11.38-12.82S247.92,8.45,254,8.45Zm27.44-.14h6.62L249.29,64h-6.55ZM250.22,21.12c0,4.54,1.3,6.77,3.75,6.77,2.23,0,3.82-2.23,3.82-6.77s-1.59-6.84-3.82-6.84C251.52,14.28,250.22,16.51,250.22,21.12ZM265.06,51.8c0-8.57,5.25-12.75,11.38-12.75s11.37,4.18,11.37,12.75-5.25,12.74-11.37,12.74S265.06,60.22,265.06,51.8Zm15.12,0c0-4.54-1.51-6.84-3.82-6.84s-3.74,2.16-3.74,6.84c0,4.46,1.37,6.77,3.74,6.77S280.18,56.26,280.18,51.8Z" transform="translate(-14 -7.81)"/>
      </g>
    </svg>
  );
};

const Stat31: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.5 56.23">
      <g id="outlined-text-31">
        <path className="stat" d="M121.63,41.36V32.64h4.61c4.25,0,7.78-2.37,7.78-6.84,0-4.1-2.52-6.91-6.92-6.91-5,0-7.48,2.95-7.77,7.63h-11c.22-8.13,5.4-16.63,19.08-16.63,11.31,0,17.36,6.41,17.36,13.83,0,6.26-3.17,10.08-8.57,12.38v.15c5.76,1.65,9.94,5.54,9.94,13,0,8.56-6.63,15.91-19.16,15.91-13.82,0-18.79-8.14-19.29-16.56H119c.5,5,3.89,7.49,8.13,7.49,4.61,0,8.14-2.88,8.14-7.49s-3-7.2-8.06-7.2Z" transform="translate(-107.2 -9.39)"/>
        <path className="stat" d="M155.62,64V55.11h13.24V27.46H156.7V19.9h3.09c3.53,0,6-.65,7.92-2.45,1.59-1.44,2.45-3.6,2.45-7h10.51V55.11H193.2V64Z" transform="translate(-107.2 -9.39)"/>
      </g>
    </svg>
  );
};

const Stat23000: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314.85 63.57">
      <g id="outlined-text-23_000_" data-name="outlined-text-23,000+">
        <path className="stat" d="M52.61,29.26C52.46,18,59.38,9.89,72.12,9.89s18.44,7.35,18.44,15.48c0,7.85-2.67,12.39-9.29,18.44l-5.91,5.4A55.63,55.63,0,0,1,69,54.39v.36H90.92V64H52.61V56.48L71.4,38.76C76.51,34,79.11,31,79.11,26.16s-2.74-7-7.2-7c-4.11,0-7.92,2.45-7.85,10.22Z" transform="translate(-52.11 -9.39)"/>
        <path className="stat" d="M112.51,41.36V32.64h4.61c4.25,0,7.78-2.37,7.78-6.84,0-4.1-2.52-6.91-6.91-6.91-5,0-7.49,2.95-7.78,7.63H99.27c.21-8.13,5.4-16.63,19.08-16.63,11.3,0,17.35,6.41,17.35,13.83,0,6.26-3.17,10.08-8.57,12.38v.15c5.76,1.65,9.94,5.54,9.94,13,0,8.56-6.63,15.91-19.16,15.91-13.82,0-18.79-8.14-19.29-16.56h11.23c.5,5,3.89,7.49,8.14,7.49,4.6,0,8.13-2.88,8.13-7.49s-3-7.2-8.06-7.2Z" transform="translate(-52.11 -9.39)"/>
        <path className="stat" d="M155.93,72.46l3.31-21.38h12.89l-7.92,21.38Z" transform="translate(-52.11 -9.39)"/>
        <path className="stat" d="M210,10c13.54,0,19.37,9.15,19.37,27.65,0,18.15-5.83,27.51-19.37,27.51-13.1,0-19.37-9.22-19.37-27.51C190.63,19.18,197,10,210,10Zm7,27.65c0-13-1.66-18.64-7-18.64s-7,5.61-7,18.64c0,13.69,1.58,18.51,7,18.51C215.55,56.19,217,50.79,217,37.68Z" transform="translate(-52.11 -9.39)"/>
        <path className="stat" d="M256.08,10c13.54,0,19.37,9.15,19.37,27.65,0,18.15-5.83,27.51-19.37,27.51-13.1,0-19.37-9.22-19.37-27.51C236.71,19.18,243.12,10,256.08,10Zm7,27.65c0-13-1.66-18.64-7-18.64s-7,5.61-7,18.64c0,13.69,1.58,18.51,7,18.51C261.63,56.19,263.07,50.79,263.07,37.68Z" transform="translate(-52.11 -9.39)"/>
        <path className="stat" d="M302.16,10c13.54,0,19.37,9.15,19.37,27.65,0,18.15-5.83,27.51-19.37,27.51-13.1,0-19.37-9.22-19.37-27.51C282.79,19.18,289.2,10,302.16,10Zm7,27.65c0-13-1.66-18.64-7-18.64s-7,5.61-7,18.64c0,13.69,1.58,18.51,7,18.51S309.15,50.79,309.15,37.68Z" transform="translate(-52.11 -9.39)"/>
        <path className="stat" d="M352.71,56.55h-9V42.44H330V34.08h13.69v-14h9v14h13.75v8.36H352.71Z" transform="translate(-52.11 -9.39)"/>
      </g>
    </svg>
  );
};

const Stat10x: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.41 56.16">
      <g id="outlined-text-10x">
        <path className="stat" d="M86.5,64V55.11H99.75V27.46H87.58V19.9h3.09c3.53,0,6-.65,7.92-2.45,1.59-1.44,2.45-3.6,2.45-7h10.51V55.11h12.53V64Z" transform="translate(-86 -9.53)"/>
        <path className="stat" d="M150,10c13.54,0,19.37,9.15,19.37,27.65,0,18.15-5.83,27.51-19.37,27.51-13.1,0-19.37-9.22-19.37-27.51C130.63,19.18,137,10,150,10Zm7,27.65c0-13-1.66-18.64-7-18.64s-7,5.61-7,18.64c0,13.69,1.58,18.51,7,18.51C155.55,56.19,157,50.79,157,37.68Z" transform="translate(-86 -9.53)"/>
        <path className="stat" d="M189.31,44l-12.6-18.51H189.1l7.13,11.24h.14l6.7-11.24h12.17L202.63,43.81,216.46,64H203.93l-8.21-12.67h-.14L187.8,64H175.7Z" transform="translate(-86 -9.53)"/>
      </g>
    </svg>
  );  
};

export {
  Stat0,
  Stat3,
  Stat89_05,
  Stat80_88,
  Stat86_63,
  Stat31,
  Stat23000,
  Stat10x,
};
