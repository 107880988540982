import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import { SectionProps } from './types';
import InteractiveText from '../InteractiveText';
import illo_1 from '../../img/illo/illo-1.svg';
import { useHistory, NavLink } from 'react-router-dom';

const Section1: React.FC<SectionProps> = ({ isTurned }) => {
  const classes = cc({
    left: true,
    turned: isTurned,
  });

  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (!isTurned && el.tagName.toLowerCase() !== 'a') {
      history.push('intro');
    }
    return;
  };
  return (
    <section id="section-1" className={classes}>
      <article className="bg-black" onClick={handleClick}>
        <Container>
          <div style={{
            paddingTop: '0.25rem',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
          }}>
            {/* <img style={{
              height: '84px',
              marginLeft: '-20px'
            }} src="./img/CareSignal_DRPM_logo.svg" alt="CareSignal Deviceless Remote Patient Monitoring" /> */}
            <img 
              style={{
                height: '1.375rem',
                marginTop: '0.75rem',
                marginLeft: '0.25rem',
              }}
              src="./img/Lightbeam Health Solutions Logo – Inverse.png"
              alt="Lightbeam Health Solutions Logo"
            />
          </div>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <div className="masthead">
                <div className="caption">&nbsp;</div>
                <img src={illo_1} alt="" />
                <h1 className="sans-serif">
                  There’s a lot of noise around Artificial Intelligence. Healthcare isn’t
                  immune to&nbsp;the <InteractiveText image={{
                    src: '../img/cursor/cursor-image-ibm-watson.png',
                    alt: 'Text, "We see you, IBM Watson!" rotates around two cartoon eyes.',
                  }}>empty</InteractiveText> hype.
                </h1>
              </div>
            </Col>
          <Col lg={6} offset={{ lg: 3 }}>
              <p className="serif">
                <strong
                  >CareSignal AI cuts through the noise–creating real value for our
                  clients and their patients today.</strong
                >
              </p>
              <p className="serif">
                We pioneered Deviceless Remote Patient Monitoring, the foundation upon
                which we built our next innovation: CareSignal AI to predict and prevent&nbsp;
                <InteractiveText description="Patient disengagement is the key factor that impacts clinical outcomes and ROI. Without clinically-relevant patient engagement, care teams are ill-positioned to provide proactive care, and outcomes and ROI suffer.">patient disengagement</InteractiveText>. Read on to
                learn about our novel approaches to collecting high-quality data,
                architecting industry-leading AI, and (most importantly) producing
                outcomes.
              </p>
              <p className="text-center"><NavLink className="navigation-button" to="rare-data">Next →</NavLink></p>
            </Col>
          </Row>
        </Container>
      </article>
    </section>
  );
};

export default Section1;
