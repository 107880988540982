import React from 'react';
import cc from 'classcat';
import './Statistic.css';

type StatSize = 'md' | 'lg' | 'xl';

interface StatisticProps {
  number: string | number | JSX.Element;
  description: string;
  size?: StatSize;
}

const Statistic: React.FC<StatisticProps> = ({ number, description, size = 'md' }) => {
  
  const statClasses = cc({
    'statistic': true,
    'statistic-lg': size === 'lg',
    'statistic-xl': size === 'xl',
  });

  return (
    <div className={statClasses}>
      <div className="number monospace">{number}</div>
      <div className="description">{description}</div>
    </div>
  );
};

export default Statistic;
