import React, { useState, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import './chart.css';

interface EngagementDataProps {
  Days: number;
  Engagement:  number;
}

const Chart1 = () => {
  const [d3Data, setD3Data] = useState<Record<string,EngagementDataProps[]>>({});
  const dummyChart = document.createElement('span');
  const [chart, setChart] = useState<HTMLElement>(dummyChart);
  const HEADERS = [
    'Days', // Day (integer, x-axis)
    'P1',  // Engagement (float, y-axis)
  ];
  const SERIES:Record<string,EngagementDataProps[]> = {
    P1: [],
  };

  useEffect(() => {
    d3.csv('/data/random12_2020825.csv', (d) => {
      let _d:any = null;
      if (d[HEADERS[0]]) { 
        let days = 0;
        for(const header of HEADERS) {
          if (header === HEADERS[0] && d[header]) {
            days = parseInt(d[header], 10);
            continue;
          }
          const engagement = d[header];
          SERIES[header].push({
            Days: days,
            Engagement: (engagement || 0) as number,
          });
        }
        _d = d;
      }
      return _d;
    }).then((data) => {
      if (data.length <= 1) {
        console.error('Error: Data did not load correctly.');
      } else {
        setD3Data(SERIES);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(d3Data).length > 0 && chart !== dummyChart) {
      if (chart.children.length === 0) {
        const height = chart.offsetHeight;
        const width = chart.offsetWidth;
        const svg = d3
          .select('#chart_1')
            .append('svg')
              .attr('height', height)
              .attr('width', width)
            .append('g');

        const xScale = d3.scaleLinear()
          .domain([0, 2000])
          .range([0, width]);

        const yScale = d3.scaleLinear()
          .domain([0,1])
          .range([height, 0]);

        for(const [key, value] of Object.entries(d3Data)) {
          // @ts-expect-error Overload does exist
          svg.append('g')
            .selectAll('.dot')
            .data(value)
            .enter()
            .append('circle')
              .attr('id', key)
              .attr('class', 'dot')
              .attr('r', 1)
              .attr('cx', (d) => xScale(d.Days))
              .attr('cy', () => yScale(Math.random())); // Create noise
        }
        // TODO: Mouseover tooltips: https://bl.ocks.org/Qizly/5a78caaf03ed96757e72
        svg.append('rect')
          .attr('class', 'overlay')
          .attr('width', width - 40)
          .attr('height', height - 60);
      }
      return;
    }
  }, [d3Data, chart]);

  const chartRef = useCallback((node: HTMLDivElement) => {
    if (chart === dummyChart && node) {
      setChart(node);
    }
  }, []);

  return (
    <div ref={chartRef} id="chart_1" className="chart-container"></div>
  );
};

export default Chart1;
