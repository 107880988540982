import React, { useState, useLayoutEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { SectionProps } from './types';
import Statistic from '../Statistic';
import illo_3 from '../../img/illo/illo-3.svg';
import { NavLink, useHistory } from 'react-router-dom';

const Section3: React.FC<SectionProps> = ({ isTurned }) => {
  const classes = cc({
    left: true,
    turned: isTurned,
  });

  const basePatientDays = 5800000;
  const patientDayFormat = '0,0.00';
  const [patientDays, setPatientDays] = useState(numeral(basePatientDays).format(patientDayFormat));
  const [clinicianLives, setClinicianLives] = useState(numeral(11.30).format(patientDayFormat));

  useLayoutEffect(() => {
    // Based off of this note https://caresignal.slack.com/archives/C04HW77FN/p1591621394000400
    const base = new Date(2020, 5, 8, 8, 3);
    // lifetimes = patient days collected / 100-patient panel / 12 hours a day / 365 days a year
    const updateTime = () => {
      const now = moment.now();
      const milliseconds = moment(now).diff(base, 'milliseconds');
      const _patientDays = milliseconds / 1000 / 8 + basePatientDays;
      setPatientDays(numeral(_patientDays).format(patientDayFormat));
      setClinicianLives(numeral(_patientDays / 100 / 12 / 365).format(patientDayFormat));
    };
    setInterval(updateTime, 250);
  }, []);
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (!isTurned && el.tagName.toLowerCase() !== 'a') {
      history.push('data-collection');
    }
    return;
  };
  return (
    <section id="section-3" className={classes}>
      <article className="bg-purple-blue" onClick={handleClick}>
        <Container>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <div className="masthead">
                <div className="caption">Data Collection</div>
                <img src={illo_3} alt="" />
                <h2 className="serif">CareSignal excels at uncovering and capturing rare data.</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <p className="monospace">
                Our formula of accessible technology, applied behavioral science, and evidence-based product design delivers proven, replicable clinical and financial outcomes. De-identified data and metadata collected by Deviceless Remote Patient Monitoring represent a combined several million patient-days across some of the toughest conditions and patient populations in the country.
              </p>
              <p className="monospace">You'd never see this much data in a lifetime of clinical care management. Actually, not even in several lifetimes.</p>
            </Col>
          </Row>
        </Container>
        <div style={{ textAlign: 'center' }}>
          <Statistic number="24+" description="condition-specific programs" />
          <Statistic number={patientDays} description="patient days of data and metadata" size="lg" />
          <Statistic number={clinicianLives} description="lifetimes of care manager-driven patient interactions" size="xl" />
        </div>
        <p className="text-center"><NavLink className="navigation-button" to="architecture">Next →</NavLink></p>
      </article>
    </section>
  );
};

export default Section3;
