import React from 'react';
import CursorBlock from '../Cursor/CursorBlock';
import './InteractiveBlock.css';

interface InteractiveBlockProps {
  number: number | JSX.Element;
  headline: string | JSX.Element;
  description: string;
  type: string | 'circle' | 'square' | 'diamond';
  hoverImage: {
    src: string;
    alt: string;
  };
}

const InteractiveBlock: React.FC<InteractiveBlockProps> = ({
  number,
  headline,
  description,
  hoverImage,
  type,
}) => {
  return (
    <CursorBlock
      className="bg-black"
      image={hoverImage}
    >
      <aside className="interactive-block">
        <div className={`shape is-${type}`}>
          {/* TODO: Convert to SVG */}
          <div className="number">{number}</div>
        </div>
        {/* TODO: Convert to SVG */}
        <h3 className="headline">{headline}</h3>
        <p className="description">{description}</p>
      </aside>
    </CursorBlock>
  );
};

export default InteractiveBlock;
