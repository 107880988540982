import React from 'react';
import { NavLink } from 'react-router-dom';

import nav_1_default from './img/nav_1_default.svg';
import nav_1_active from './img/nav_1_active.svg';
import nav_1_visited from './img/nav_1_visited.svg';
import nav_2_default from './img/nav_2_default.svg';
import nav_2_active from './img/nav_2_active.svg';
import nav_2_visited from './img/nav_2_visited.svg';
import nav_3_default from './img/nav_3_default.svg';
import nav_3_active from './img/nav_3_active.svg';
import nav_3_visited from './img/nav_3_visited.svg';
import nav_4_default from './img/nav_4_default.svg';
import nav_4_active from './img/nav_4_active.svg';
import nav_4_visited from './img/nav_4_visited.svg';
import nav_5_default from './img/nav_5_default.svg';
import nav_5_active from './img/nav_5_active.svg';
import nav_5_visited from './img/nav_5_visited.svg';
import nav_6_default from './img/nav_6_default.svg';
import nav_6_active from './img/nav_6_active.svg';
import nav_6_visited from './img/nav_6_visited.svg';
import nav_7_default from './img/nav_7_default.svg';
import nav_7_active from './img/nav_7_active.svg';
import nav_7_visited from './img/nav_7_visited.svg';

interface NavigationItemProps {
  index: number;
  text: string;
  next: string;
  defaultActive?: boolean;
  defaultVisited?: boolean;
}

const SVGS = [
  {
    default: nav_1_default,
    active: nav_1_active,
    visited: nav_1_visited,
  }, {
    default: nav_2_default,
    active: nav_2_active,
    visited: nav_2_visited,
  }, {
    default: nav_3_default,
    active: nav_3_active,
    visited: nav_3_visited,
  }, {
    default: nav_4_default,
    active: nav_4_active,
    visited: nav_4_visited,
  }, {
    default: nav_5_default,
    active: nav_5_active,
    visited: nav_5_visited,
  }, {
    default: nav_6_default,
    active: nav_6_active,
    visited: nav_6_visited,
  }, {
    default: nav_7_default,
    active: nav_7_active,
    visited: nav_7_visited,
  },
];

const NavigationItem :React.FC<NavigationItemProps> = ({ defaultActive, defaultVisited, index = 0, next, text }) => {
  const svg = SVGS[index];
  return (
    <div className={`navigation-item${(defaultActive ? ' is-active' : '')}${(defaultVisited ? ' is-visited' : '')}`}>
      <div className="label">
        <span className="text">{text}</span>
        <div className="line">
          <div className="dot-container vertical">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      </div>
      <NavLink to={`${next}`}>
        <div className="shape-container">
          <img className="shape active" src={`${svg.active}`} alt={`Link for ${text} is STATE.`} />
          <img className="shape visited" src={`${svg.visited}`} alt={`Link for ${text} is STATE.`} />
          <img className="shape default" src={`${svg.default}`} alt={`Link for ${text} is STATE.`} />
        </div>
      </NavLink>
    </div>
  );
};

export default NavigationItem;
