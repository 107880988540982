import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import { SectionProps } from './types';
import { useHistory } from 'react-router-dom';

// 3D dependencies
import { Canvas, GroupProps, useFrame } from '@react-three/fiber';
import InteractiveText from '../InteractiveText';

const ConeT: React.FC = () => {
  const coneRef: React.RefObject<GroupProps | null | undefined> = useRef();
  useFrame(() => {
    if (coneRef.current) {
      const cone: GroupProps = coneRef.current;
      if (cone.rotation) {
        // @ts-expect-error Euler does have a y property
        cone.rotation.y += 0.0075;
        // @ts-expect-error Euler does have an x property
        cone.rotation.x += 0.005;
      }
    }
  });
  return (
    // @ts-expect-error ref still works
    <group ref={coneRef}>
      <mesh >
        <ambientLight intensity={0.125} color="#ffc65c" />
        <directionalLight position={[0,-0.125,1]} intensity={3} />
        <meshStandardMaterial attach="material" color="white" />
        <coneGeometry args={[1.5,1.75,8]} />
      </mesh>
    </group>
  );
};


const Section7: React.FC<SectionProps> = ({ isTurned }) => {
  const classes = cc({
    right: true,
    turned: isTurned,
  });
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (isTurned && el.tagName.toLowerCase() !== 'a') {
      history.push('pricing');
    }
    return;
  };
  return (
    <section id="section-7" className={classes}>
      <article className="bg-black" onClick={handleClick}>
      <Container>
        <Row>
          <Col lg={10} offset={{ lg: 1 }}>
            <div className="masthead">
              <div className="caption">At-Risk Pricing</div>
              <Canvas style={{
                height: '342px',
                width: '270px',
                margin: 'auto',
              }}>
                <ambientLight color="goldenrod" />
                <pointLight position={[10, 10, 10]} color="white" />
                <pointLight position={[-10, 10, 10]} color="green" />
                <ConeT />
              </Canvas>
              <h2 className="sans-serif">We stand behind our technology. But most importantly, we stand by our industry&nbsp;partners.</h2>
            </div>
          </Col>
          <Col lg={6} offset={{ lg: 3 }}>
            <p className="serif">COVID-19 has turned the world on its head. It’s critical for tech innovators to step up and support those who are risking their lives to  save the lives of others every day. We’re keeping our COVID programs free, and now, we’re going a step further. CareSignal now offers risk-based pricing on any contract:&thinsp;&thinsp;
              <InteractiveText
                description="CareSignal takes risk with a significantly reduced price, and we put our skin in the game and work with you to generate directly attributable value for over two dozen programs, such as CHF, COPD, diabetes, depression, SDOH, and more.">
                  when&nbsp;you&nbsp;win,&nbsp;we&nbsp;win
              </InteractiveText>.</p>
              <p className="serif">We’ve served dozens of partners across the country. We have 10+ peer-reviewed publications. We provide the best engagement, capturing clinically actionable data, and delivering high ROI with AI-powered Deviceless Remote Patient Monitoring.</p>
              <p className="serif">It would be our privilege to serve you, too.</p>
          </Col>
        </Row>
        <p style={{
          textAlign: 'center',
        }}>
          <a className="cta monospace" href="https://lightbeamhealth.com/request-a-demo/?utm_source=csai&utm_medium=website&utm_campaign=csai">Speak with&nbsp;an&nbsp;expert</a>
        </p>
      </Container>
      </article>
    </section>
  );
};

export default Section7;
