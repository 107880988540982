import React from 'react';
import { animated } from '@react-spring/web';
import { useGesture } from 'react-use-gesture';

const Cursor = ({ children } : { children: React.ReactNode }): JSX.Element => {
  const bind = useGesture({
    onMove: ({ xy: [x, y] }) => {
      const cursor = document.getElementById('cursor') as HTMLElement;
      cursor.style.left = `${x}px`;
      cursor.style.top = `${y}px`;
    },
    onMouseDown: () => {
      const cursor = document.getElementById('cursor') as HTMLElement;
      cursor.classList.add('down');
    },
    onMouseUp: () => {
      const cursor = document.getElementById('cursor') as HTMLElement;
      cursor.classList.remove('down');
    },
  });
  return (
    <animated.span {...bind()} >
      <span id="cursor"></span>
      {children}
    </animated.span>
  );
};

export default Cursor;
