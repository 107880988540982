import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import cc from 'classcat';
import { SectionProps } from './types';
import InteractiveBlock from '../InteractiveBlock';
import InteractiveText from '../InteractiveText';
import illo_2 from '../../img/illo/illo-2.svg';
import {
  Number1,
  Number2,
  Number3,
  Text1,
  Text2,
  Text3,
} from '../InteractiveBlock/svgs';
import { NavLink, useHistory } from 'react-router-dom';

const Section2: React.FC<SectionProps> = ({ isTurned }) => {
  const classes = cc({
    left: true,
    turned: isTurned,
  });
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.persist();
    const el = e.target as HTMLElement;
    if (!isTurned && el.tagName.toLowerCase() !== 'a') {
      history.push('rare-data');
    }
    return;
  };
  return (
    <section id="section-2" className={classes}>
      <article className="bg-purple" onClick={handleClick}>
        <Container>
          <Row>
            <Col lg={10} offset={{ lg: 1 }}>
              <div className="masthead">
                <div className="caption">Rare Data</div>
                <img src={illo_2} alt="" />
                <h2 className="serif">
                  Actionable insights emerge from AI&nbsp;trained on relevant, <span style={{ whiteSpace: 'nowrap' }}>high-quality</span>&nbsp;data.
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} offset={{ lg: 3 }}>
              <p className="serif">
                <strong>Conventional wisdom dictates that organizations need big data to train
                AI. Our approach is more <em>nuanced</em>.</strong> We collect big data, too, but we go
                beyond “big” to focus on quality. We use a source of data that’s often
                overlooked: <InteractiveText image={{
                  src: '../img/cursor/cursor-image-found-rare-data.png',
                  alt: '"You found the rare data!" rotates around a crown.',
                }}
                  className="bg-black"
                ><em>rare data</em></InteractiveText>.
              </p>
              <p className="serif">
                So, what do we mean by ‘rare data’?
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={4} id="interactiveBlock_1">
              <InteractiveBlock 
                number={<Number1 />}
                headline={<Text1 />}
                description="Rare data includes clinically-relevant, condition-specific sign and symptom data. It can even include the metadata from a patient’s interaction with technology over time."
                type="circle"
                hoverImage={{
                  src: '../img/cursor/cursor-image-catch-if-you-can.png',
                  alt: '"Catch it if you can!" rotates around a puff of air.',
                }}
              />
            </Col>
            <Col lg={4} id="interactiveBlock_2">
              <InteractiveBlock 
                number={<Number2 />}
                headline={<Text2 />}
                description="Rare data’s window of opportunity to inform clinical care or power predictive models is often less than ten days."
                type="square"
                hoverImage={{
                  src: '../img/cursor/cursor-image-use-while-hot.png',
                  alt: '"Use it while it\'s hot!" rotates around a flame.',
                }}
              />
            </Col>
            <Col lg={4} id="interactiveBlock_3">
              <InteractiveBlock 
                number={<Number3 />}
                headline={<Text3 />}
                description="Rare data, if used correctly, impact outcomes on their own. They become more powerful when assimilated into predictive models, delivering better outcomes and higher ROI."
                type="diamond"
                hoverImage={{
                  src: '../img/cursor/cursor-image-actually-powers-models.png',
                  alt: '"It actually powers AI models!" rotates around a bionic, flexing arm.',
                }}
              />
            </Col>
          </Row>
          <p className="text-center"><NavLink className="navigation-button" to="data-collection">Next →</NavLink></p>
        </Container>
      </article>
    </section>
  );
};

export default Section2;
