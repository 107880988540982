import React, { useState } from 'react';
import cc from 'classcat';
import { useSpring, animated } from '@react-spring/web';
import { useHover, useMove } from 'react-use-gesture';

interface CursorBlockProps {
  className?: string;
  image?: {
    src: string;
    alt: string;
  };
  description?: string | JSX.Element;
  children?: React.ReactNode;
}
const CursorBlock: React.FC<CursorBlockProps> = ({ children, className, image, description }) => {
  const [hoverProps, setHover] = useSpring(() => ({ x: 0, y: 0 }));
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [classes, setClasses] = useState<any>(
    cc([{
      'expanded': false,
      'cursor': true,
      'cursor-image': (image),
      'cursor-description': (description),
    }, className])
  );
  const bindHover = useHover(({ active }) => {
    const cursor = document.getElementById('cursor') as HTMLElement;
    setIsHovered(active);
    setClasses(cc([{
      'expanded': active,
      'cursor': true,
      'cursor-image': (image),
      'cursor-description': (description),
    }, className]));
    if (active) {
      cursor.classList.add('hide');
    } else {
      cursor.classList.remove('hide');
    }
  });
  const bindMove = useMove(({ xy: [x,y] }) => {
    setHover({ x: x, y: y });
  });
  const imgProps = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(-360deg)',
    },
    loop: true,
    config: {
      duration: 5000,
    },
  });

  const content = 
    (image)
      ? <animated.img style={(isHovered) ? imgProps : undefined} className="interactive-text__image" src={image.src} alt={image.alt} />
      : <animated.div className="interactive-text__text">{description}</animated.div>;

  return (
    <span tabIndex={0} className="interactive-text" {...bindHover()} {...bindMove()}>
      {children}
      <animated.span style={{ x: hoverProps.x, y: hoverProps.y }} className={classes}>
        {content}
      </animated.span>
    </span>
  );
};

export default CursorBlock;
