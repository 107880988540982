import React from 'react';

const Number1: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.09 21.08">
      <g id="outlined-text-1">
        <path className="number" d="M143.47,24.33V21h5V10.62h-4.56V7.78H145a4,4,0,0,0,3-.92,3.32,3.32,0,0,0,.92-2.62h3.94V21h4.7v3.35Z" transform="translate(-142.97 -3.74)"/>
      </g>
    </svg>
  );
};

const Number2: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.36 21.3">
      <g id="outlined-text-2">
        <path className="number" d="M142.82,11.29C142.76,7.08,145.35,4,150.13,4S157,6.78,157,9.83s-1,4.64-3.48,6.91l-2.21,2A20.83,20.83,0,0,1,149,20.71v.13h8.21v3.49H142.82V21.49l7-6.64c1.92-1.78,2.89-2.91,2.89-4.72a2.41,2.41,0,0,0-2.7-2.65c-1.54,0-3,.92-2.94,3.84Z" transform="translate(-142.32 -3.53)"/>
      </g>
    </svg>
  );
};

const Number3: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.44 21.7">
      <g id="outlined-text-3">
        <path className="number" d="M148,15.82V12.56h1.73A2.61,2.61,0,0,0,152.64,10a2.41,2.41,0,0,0-2.59-2.59,2.7,2.7,0,0,0-2.91,2.86H143c.08-3,2-6.23,7.16-6.23,4.23,0,6.5,2.4,6.5,5.18a4.7,4.7,0,0,1-3.21,4.64v.06a4.68,4.68,0,0,1,3.72,4.86c0,3.21-2.48,6-7.18,6-5.18,0-7-3.05-7.23-6.21H147a2.9,2.9,0,0,0,3.05,2.81,2.8,2.8,0,0,0,3-2.81c0-1.78-1.11-2.7-3-2.7Z" transform="translate(-142.26 -3.53)"/>
      </g>
    </svg>
  );
};

const Text1: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.68 88.2">
      <g id="outlined-text-extreme-scarcity">
        <path className="text" d="M4.28,4.73H20.8V7.65H7.63v9.29h11.3v2.84H7.63v9.57H21v2.92H4.28Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M33.19,22.48,26.53,13.3h3.71L35,20.07h.15L40,13.3h3.38l-6.62,9L44,32.27H40.28l-5.4-7.52h-.11l-5.36,7.52H26Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M55.51,16H48.63V13.3h5.11c1.48,0,2-.5,2-2V7.47h3V13.3h7.42V16H58.71V26.73c0,2.48,1.4,3.2,3.38,3.2a11.06,11.06,0,0,0,4-.76v2.7a11.3,11.3,0,0,1-4.25.83c-4.65,0-6.37-2.16-6.37-5.72Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M78.55,24.13v8.14H75.31v-19h3.06V18h.07c1.37-3.75,4-5.08,7.81-5.08A9.77,9.77,0,0,1,90,13.7v3.06a13.75,13.75,0,0,0-4.5-.8C81,16,78.55,19.09,78.55,24.13Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M104.57,32.74c-6,0-8.92-4.21-8.92-9.86s3.13-10,8.82-10c5.9,0,8.24,4.39,8.24,9.18v1.66H98.92c.22,3.92,2,6.55,5.76,6.55,3.39,0,4.25-1.8,4.61-2.92h3.1C111.67,30.58,108.75,32.74,104.57,32.74Zm4.83-11.88c-.11-3.17-1.44-5.54-5-5.54-3.24,0-5,2.34-5.43,5.76h10.4Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M117.82,13.3h2.78V16h.14a4,4,0,0,1,4-3.21A3.61,3.61,0,0,1,128.59,16h.1a4,4,0,0,1,4.11-3.13c2.81,0,4,1.62,4,5.08V32.27h-3.13V18.59c0-1.58,0-3.13-1.94-3.13s-2.84,1.76-2.84,5.25V32.27h-3.14V18.59c0-1.58-.11-3.13-2-3.13s-2.8,1.76-2.8,5.25V32.27h-3.14Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M150.65,32.74c-6,0-8.92-4.21-8.92-9.86s3.13-10,8.82-10c5.9,0,8.24,4.39,8.24,9.18v1.66H145c.22,3.92,1.95,6.55,5.76,6.55,3.39,0,4.25-1.8,4.61-2.92h3.1C157.75,30.58,154.83,32.74,150.65,32.74Zm4.83-11.88c-.11-3.17-1.44-5.54-5-5.54-3.24,0-5,2.34-5.43,5.76h10.4Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M16.34,70.55c-.33-1.94-1.91-2.7-4.32-2.7-2.59,0-4,1.08-4,2.85s1.08,2.44,3.24,2.8L14,74c4.18.72,5.84,2.34,5.84,5.33,0,3.63-2.45,5.94-8,5.94-4.17,0-7.27-1.59-7.77-5.83H7.27c.25,1.83,1.58,3.31,4.64,3.31,3.31,0,4.79-1,4.79-3.1,0-1.73-.87-2.37-3.67-2.84l-3.14-.54c-2.95-.51-5-1.91-5-5.33,0-3.09,2.41-5.58,7.12-5.58s7.13,2.13,7.42,5.22Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M40.24,71.52a4.52,4.52,0,0,0-4.75-3.63c-3.67,0-5.33,2.88-5.33,7.3,0,4.58,1.34,7.46,5.33,7.46a4.78,4.78,0,0,0,4.93-4.07H43.7c-.58,4.17-3.93,6.66-8.21,6.66-5.72,0-8.67-3.64-8.71-9.9,0-6.08,3.28-10,8.89-10,3.85,0,7.27,1.77,7.92,6.19Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M55.87,85.09c-3.82,0-6.16-2.12-6.16-5.5a4.87,4.87,0,0,1,2.85-4.68c2-1,5.11-1.44,9.35-1.59v-1c0-3.2-1.44-4.5-4.14-4.5-3.09,0-4,1.77-4.1,3.24H50.54c.22-3.17,2.59-5.76,7.23-5.76,4.4,0,7.24,2,7.24,7V80.7c0,1.15.32,1.44,1.48,1.44h.64v2.63h-1c-2.85,0-3.82-1-4-3.42H62A6.31,6.31,0,0,1,55.87,85.09Zm.57-2.62c2.41,0,5.47-1.62,5.47-6.3v-.44c-4.1.19-6.69.51-7.91,1.41a2.61,2.61,0,0,0-1,2.27C53,81.42,54.32,82.47,56.44,82.47Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M78.55,76.63v8.14H75.31v-19h3.06v4.72h.07c1.37-3.75,4-5.08,7.81-5.08A9.77,9.77,0,0,1,90,66.2v3.06a13.75,13.75,0,0,0-4.5-.8C81,68.46,78.55,71.59,78.55,76.63Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M109.36,71.52a4.52,4.52,0,0,0-4.75-3.63c-3.67,0-5.33,2.88-5.33,7.3,0,4.58,1.34,7.46,5.33,7.46a4.78,4.78,0,0,0,4.93-4.07h3.28c-.58,4.17-3.93,6.66-8.21,6.66-5.72,0-8.67-3.64-8.71-9.9,0-6.08,3.28-10,8.89-10,3.85,0,7.27,1.77,7.92,6.19Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M119,84.77v-2.7h7V68.39h-5.94V65.8h9V82.07h7.09v2.7Zm5.87-27.36h4.82v4.83h-4.82Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M147.67,68.5h-6.88V65.8h5.11c1.48,0,2-.5,2-2V60h3V65.8h7.42v2.7h-7.42V79.23c0,2.48,1.4,3.2,3.38,3.2a11.06,11.06,0,0,0,4-.76v2.7a11.3,11.3,0,0,1-4.25.83c-4.65,0-6.37-2.16-6.37-5.72Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M164.44,65.8h3.46L172.65,78c.68,1.8,1,2.95,1,2.95h.14s.36-1.19,1-3l4.46-12.17h3.28L174.77,86c-1.72,4.5-3.45,6-7,6a10.3,10.3,0,0,1-3-.39v-2.7a12.17,12.17,0,0,0,2.81.36c2.16,0,2.92-.65,4-3.42l.39-1Z" transform="translate(-3.57 -4.23)"/>
        <path className="text" d="M193.93,80h4.82v4.82h-4.82Z" transform="translate(-3.57 -4.23)"/>
      </g>
    </svg>
  );
};

const Text2: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.32 89.14">
      <g id="outlined-text-brief-actionability">
        <path className="text" d="M3.74,32.27V4.73h9c4.9,0,8.18,2.2,8.18,6.7,0,3.35-1.7,5.33-4.83,6.08v.07a6.62,6.62,0,0,1,5.69,6.73c0,6.2-4.32,8-9.86,8Zm8.42-15.8c2.92,0,5.33-1.15,5.33-4.68,0-3-1.84-4.39-4.9-4.39H7.09v9.07ZM12,29.61c4,0,6.37-1.37,6.37-5.51,0-3.64-2.3-5-6.66-5H7.09V29.61Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M32.47,24.13v8.14H29.23v-19h3.06V18h.07c1.37-3.75,4-5.08,7.81-5.08A9.77,9.77,0,0,1,44,13.7v3.06a13.75,13.75,0,0,0-4.5-.8C34.88,16,32.47,19.09,32.47,24.13Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M49.89,32.27v-2.7h7V15.89H50.94V13.3h9V29.57h7.09v2.7ZM55.76,4.91h4.82V9.74H55.76Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M81.53,32.74c-6,0-8.92-4.21-8.92-9.86s3.13-10,8.82-10c5.9,0,8.24,4.39,8.24,9.18v1.66H75.88c.22,3.92,2,6.55,5.76,6.55,3.39,0,4.25-1.8,4.61-2.92h3.1C88.63,30.58,85.71,32.74,81.53,32.74Zm4.83-11.88c-.11-3.17-1.44-5.54-5-5.54-3.24,0-5,2.34-5.43,5.76h10.4Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M105.33,32.27h-3.2V16H95.25V13.3h6.88V9.45c0-4,2.91-5.65,6.58-5.65a12.78,12.78,0,0,1,4,.57V7.11a11.91,11.91,0,0,0-3.93-.61c-2.23,0-3.49.9-3.49,3.24V13.3h7.42V16h-7.42Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M9.79,85.09C6,85.09,3.63,83,3.63,79.59a4.87,4.87,0,0,1,2.85-4.68c2-1,5.11-1.44,9.35-1.59v-1c0-3.2-1.44-4.5-4.14-4.5-3.09,0-3.95,1.77-4.1,3.24H4.46c.22-3.17,2.59-5.76,7.23-5.76,4.4,0,7.24,2,7.24,7V80.7c0,1.15.32,1.44,1.48,1.44h.64v2.63h-1c-2.85,0-3.82-1-4-3.42h-.22A6.31,6.31,0,0,1,9.79,85.09Zm.57-2.62c2.41,0,5.47-1.62,5.47-6.3v-.44c-4.1.19-6.69.51-7.91,1.41a2.61,2.61,0,0,0-1,2.27C6.87,81.42,8.24,82.47,10.36,82.47Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M40.24,71.52a4.52,4.52,0,0,0-4.75-3.63c-3.67,0-5.33,2.88-5.33,7.3,0,4.58,1.34,7.46,5.33,7.46a4.78,4.78,0,0,0,4.93-4.07H43.7c-.58,4.17-3.93,6.66-8.21,6.66-5.72,0-8.67-3.64-8.71-9.9,0-6.08,3.28-10,8.89-10,3.85,0,7.27,1.77,7.92,6.19Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M55.51,68.5H48.63V65.8h5.11c1.48,0,2-.5,2-2V60h3V65.8h7.42v2.7H58.71V79.23c0,2.48,1.4,3.2,3.38,3.2a11.06,11.06,0,0,0,4-.76v2.7a11.3,11.3,0,0,1-4.25.83c-4.65,0-6.37-2.16-6.37-5.72Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M72.93,84.77v-2.7h7V68.39H74V65.8h9V82.07H90.1v2.7ZM78.8,57.41h4.82v4.83H78.8Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M95.32,75.27c0-6.23,3.17-9.94,8.86-9.94S113,69,113,75.27s-3.16,10-8.85,10S95.32,81.49,95.32,75.27Zm3.35,0c0,4.6,1.69,7.45,5.51,7.45s5.51-2.81,5.51-7.45-1.73-7.42-5.51-7.42S98.67,70.66,98.67,75.27Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M131.68,73.07c0-1.37,0-2.88-.65-3.78a3.45,3.45,0,0,0-3-1.26c-3.53,0-5.11,2.27-5.11,5.36V84.77h-3.28v-19h3.06V69h.07a6.64,6.64,0,0,1,6.12-3.67c3.64,0,6.05,1.73,6.05,6.23V84.77h-3.28Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M148,85.09c-3.82,0-6.16-2.12-6.16-5.5a4.87,4.87,0,0,1,2.85-4.68c2-1,5.11-1.44,9.35-1.59v-1c0-3.2-1.44-4.5-4.14-4.5-3.09,0-4,1.77-4.1,3.24H142.7c.22-3.17,2.59-5.76,7.23-5.76,4.4,0,7.24,2,7.24,7V80.7c0,1.15.32,1.44,1.48,1.44h.64v2.63h-1c-2.85,0-3.82-1-4-3.42h-.22A6.31,6.31,0,0,1,148,85.09Zm.57-2.62c2.41,0,5.47-1.62,5.47-6.3v-.44c-4.1.19-6.69.51-7.91,1.41a2.61,2.61,0,0,0-1,2.27C145.11,81.42,146.48,82.47,148.6,82.47Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M165.16,56.41h3.24V68.93h0a7.08,7.08,0,0,1,6.37-3.6c4.21,0,7.92,2.49,7.92,9.68,0,6.92-3.71,10.23-8.17,10.23a7,7,0,0,1-6.26-3.49h-.08v3h-3.06ZM179.45,75c0-4-1.26-7.09-5.4-7.09-3.63,0-5.75,2.85-5.75,6v2c0,3.74,2,6.69,5.79,6.69C177.69,82.68,179.45,80,179.45,75Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M188.13,84.77v-2.7h7V68.39h-5.94V65.8h9V82.07h7.09v2.7ZM194,57.41h4.82v4.83H194Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M211.17,84.77v-2.7h6.91V59h-6.22V56.41h9.43V82.07h7.05v2.7Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M234.21,84.77v-2.7h7V68.39h-5.94V65.8h9V82.07h7.09v2.7Zm5.87-27.36h4.82v4.83h-4.82Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M262.87,68.5H256V65.8h5.11c1.48,0,2-.5,2-2V60h3V65.8h7.42v2.7h-7.42V79.23c0,2.48,1.4,3.2,3.38,3.2a11.06,11.06,0,0,0,4-.76v2.7a11.3,11.3,0,0,1-4.25.83c-4.65,0-6.37-2.16-6.37-5.72Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M279.64,65.8h3.46L287.85,78c.69,1.8,1,2.95,1,2.95H289s.36-1.19,1.05-3l4.46-12.17h3.28L290,86c-1.72,4.5-3.45,6-7,6a10.3,10.3,0,0,1-3-.39v-2.7a12.17,12.17,0,0,0,2.81.36c2.16,0,2.92-.65,4-3.42l.39-1Z" transform="translate(-3.13 -3.3)"/>
        <path className="text" d="M309.13,80H314v4.82h-4.82Z" transform="translate(-3.13 -3.3)"/>
      </g>
    </svg>
  );
};

const Text3: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.26 88.2">
      <g id="outlined-text-extreme-scarcity">
        <path className="text" d="M7.34,21.76V32.27H4V4.73h8.14c5.32,0,9.46,2.52,9.46,8.46s-4.1,8.57-9.75,8.57Zm4.5-2.7c4,0,6.3-1.94,6.3-5.87,0-4.1-2.31-5.76-6.19-5.76H7.34V19.06Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M32.47,24.13v8.14H29.23v-19h3.06V18h.07c1.37-3.75,4-5.08,7.81-5.08A9.77,9.77,0,0,1,44,13.7v3.06a13.75,13.75,0,0,0-4.5-.8C34.88,16,32.47,19.09,32.47,24.13Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M49.24,22.77c0-6.23,3.17-9.94,8.86-9.94S67,16.54,67,22.77s-3.16,10-8.85,10S49.24,29,49.24,22.77Zm3.35,0c0,4.6,1.69,7.45,5.51,7.45s5.51-2.81,5.51-7.45-1.73-7.42-5.51-7.42S52.59,18.16,52.59,22.77Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M72.18,13.3h3.27l4.68,12.56c.58,1.55,1,3,1,3h.21s.44-1.44,1-3L87,13.3H90.1l-7.23,19H79.41Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M104.57,32.74c-6,0-8.92-4.21-8.92-9.86s3.13-10,8.82-10c5.9,0,8.24,4.39,8.24,9.18v1.66H98.92c.22,3.92,2,6.55,5.76,6.55,3.39,0,4.25-1.8,4.61-2.92h3.1C111.67,30.58,108.75,32.74,104.57,32.74Zm4.83-11.88c-.11-3.17-1.44-5.54-5-5.54-3.24,0-5,2.34-5.43,5.76h10.4Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M131.68,20.57c0-1.37,0-2.88-.65-3.78a3.45,3.45,0,0,0-3-1.26c-3.53,0-5.11,2.27-5.11,5.36V32.27h-3.28v-19h3.06v3.2h.07a6.64,6.64,0,0,1,6.12-3.67c3.64,0,6.05,1.73,6.05,6.23V32.27h-3.28Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M12.41,85.24c-6,0-8.92-4.21-8.92-9.86s3.13-10,8.82-10c5.9,0,8.24,4.39,8.24,9.18v1.66H6.76c.22,3.92,2,6.55,5.76,6.55,3.39,0,4.25-1.8,4.61-2.92h3.1C19.51,83.08,16.59,85.24,12.41,85.24Zm4.83-11.88c-.11-3.17-1.44-5.54-5-5.54-3.24,0-5,2.34-5.44,5.76H17.24Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M36.21,84.77H33V68.5H26.13V65.8H33V62c0-4,2.91-5.65,6.58-5.65a12.78,12.78,0,0,1,4,.57v2.74A11.91,11.91,0,0,0,39.7,59c-2.23,0-3.49.9-3.49,3.24V65.8h7.42v2.7H36.21Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M59.25,84.77h-3.2V68.5H49.17V65.8h6.88V62c0-4,2.91-5.65,6.58-5.65a12.78,12.78,0,0,1,4,.57v2.74A11.91,11.91,0,0,0,62.74,59c-2.23,0-3.49.9-3.49,3.24V65.8h7.42v2.7H59.25Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M72.93,84.77v-2.7h7V68.39H74V65.8h9V82.07H90.1v2.7ZM78.8,57.41h4.82v4.83H78.8Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M109.36,71.52a4.52,4.52,0,0,0-4.75-3.63c-3.67,0-5.33,2.88-5.33,7.3,0,4.58,1.34,7.46,5.33,7.46a4.78,4.78,0,0,0,4.93-4.07h3.28c-.58,4.17-3.93,6.66-8.21,6.66-5.72,0-8.67-3.64-8.71-9.9,0-6.08,3.28-10,8.89-10,3.85,0,7.27,1.77,7.92,6.19Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M125,85.09c-3.82,0-6.16-2.12-6.16-5.5a4.87,4.87,0,0,1,2.85-4.68c2-1,5.11-1.44,9.35-1.59v-1c0-3.2-1.44-4.5-4.14-4.5-3.09,0-4,1.77-4.1,3.24h-3.13c.22-3.17,2.59-5.76,7.23-5.76,4.4,0,7.24,2,7.24,7V80.7c0,1.15.32,1.44,1.48,1.44h.64v2.63h-1c-2.85,0-3.82-1-4-3.42h-.22A6.31,6.31,0,0,1,125,85.09Zm.57-2.62c2.41,0,5.47-1.62,5.47-6.3v-.44c-4.1.19-6.69.51-7.91,1.41a2.61,2.61,0,0,0-1.05,2.27C122.07,81.42,123.44,82.47,125.56,82.47Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M155.44,71.52a4.52,4.52,0,0,0-4.75-3.63c-3.67,0-5.33,2.88-5.33,7.3,0,4.58,1.34,7.46,5.33,7.46a4.78,4.78,0,0,0,4.93-4.07h3.28c-.58,4.17-3.93,6.66-8.21,6.66-5.72,0-8.67-3.64-8.71-9.9,0-6.08,3.28-10,8.89-10,3.85,0,7.27,1.77,7.92,6.19Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M164.44,65.8h3.46L172.65,78c.68,1.8,1,2.95,1,2.95h.14s.36-1.19,1-3l4.46-12.17h3.28L174.77,86c-1.72,4.5-3.45,6-7,6a10.3,10.3,0,0,1-3-.39v-2.7a12.17,12.17,0,0,0,2.81.36c2.16,0,2.92-.65,4-3.42l.39-1Z" transform="translate(-2.99 -4.23)"/>
        <path className="text" d="M193.93,80h4.82v4.82h-4.82Z" transform="translate(-2.99 -4.23)"/>
      </g>
    </svg>
  );
};
 
export {
  Number1,
  Number2,
  Number3,
  Text1,
  Text2,
  Text3,
};
